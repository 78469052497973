import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Select from 'react-select';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PersonIcon from "@material-ui/icons/Person";
import PaymentIcon from "@material-ui/icons/Payment";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HomeIcon from "@material-ui/icons/Home";


import 'bootstrap/dist/css/bootstrap.min.css';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from '@material-ui/core/Checkbox';
import api from "../../services/api";
import Loader from "react-loader-spinner";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import MuiPhoneInput from 'material-ui-phone-number';
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'


const useStyles = makeStyles(styles);

function Vendas(props) {
    const [clientes, setClientes] = useState('')
    const [itens, setItens] = useState('')
    const [filiais, setFiliais] = useState('')
    const [filial, setFilial] = useState('')
    const [vendedores, setVendedores] = useState('')


    const [supervisores, setSupervisores] = useState('')
    const [gerentes, setGerentes] = useState('')
    const [cliente, setNomeCliente] = useState('')
    const [codCliente, setCodCliente] = useState('')
    const [insProd, setInsProd] = useState('')
    const [cpf, setCPF] = useState('')
    const [rg, setRg] = useState('')
    const [emailCliente, setEmailCliente] = useState('')
    const [telCliente, setTelCliente] = useState('')

    const [rua, setRua] = useState('')
    const [cidade, setCidade] = useState('')
    const [estado, setEstado] = useState('')
    const [cep, setCep] = useState('')
    const [tel, setTel] = useState('')
    const [docRate, setDocRate] = useState('')

    const [agencia, setAgencia] = useState('0376-X')
    const [banco, setBanco] = useState('BRASIL')
    const [conta, setConta] = useState('65.276-8')

    const [forma, setForma] = useState('À Vista')
    const [par1, setPar1] = useState('')
    const [par2, setPar2] = useState('')
    const [par3, setPar3] = useState('')
    const [par4, setPar4] = useState('')

    const [nomeProduto, setNomeProduto] = useState('')
    const [codProduto, setCodigProduto] = useState('')
    const [qtde, setQtde] = useState(0)
    const [emb, setEmb] = useState('')
    const [uni, setUni] = useState(0)
    const [totProd1, setTotProd1] = useState(0)
    const [cultura, setCultura] = useState('')
    const [utilidade, setUtilidade] = useState('')

    const [nomeProduto2, setNomeProduto2] = useState('')
    const [codProduto2, setCodigProduto2] = useState('')
    const [qtde2, setQtde2] = useState(0)
    const [emb2, setEmb2] = useState('')
    const [uni2, setUni2] = useState(0)
    const [totProd2, setTotProd2] = useState(0)
    const [cultura2, setCultura2] = useState('')
    const [utilidade2, setUtilidade2] = useState('')

    const [nomeProduto3, setNomeProduto3] = useState('')
    const [codProduto3, setCodigProduto3] = useState('')
    const [qtde3, setQtde3] = useState(0)
    const [emb3, setEmb3] = useState('')
    const [uni3, setUni3] = useState(0)
    const [cont, setCont] = useState(0)


    const handleAddProduct = () => {
        if (cont < 2) { // Altera 3 para 2, permitindo adicionar até 2 produtos
            setCont(cont + 1);
        }
    };

    const handleRemoveProduct = () => {
        if (cont > 0) {
            setCont(cont - 1);
        }
    };

    const [contCad, setContCad] = useState(1)
    const [totProd3, setTotProd3] = useState(0)
    const [cultura3, setCultura3] = useState('')
    const [utilidade3, setUtilidade3] = useState('')

    const [iniDate, setIniDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [frete, setFrete] = useState('')
    const [observ, setObserv] = useState('')

    const [endEntrega, setEndEntrega] = useState('')
    const [cidadeEntrega, setCidadeEntrega] = useState('')
    const [estadoEntrega, setEstadoEntrega] = useState('')
    const [CEPEntrega, setCEPEntrega] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhoneEntrega] = useState('')
    const [caixaPostal, setCaixaPostal] = useState('')
    const [contrato, setContrato] = useState('')
    const [care, setCare] = useState('')
    const [dataCond, setDataCond] = useState('')


    
    const [vendedorRet, setVendedorRet] = useState([]); // Array vazio como estado inicial
    const [selVendedor, setSelVendedor] = useState('');

    const [vendedor, setVendedor] = useState('');


    const [comprador, setComprador] = useState('')
    const [moeda, setMoeda] = useState('R$')

    const [origemRet, setOrigemRet] = useState([]); // Array vazio como estado inicial
    const [selOrigem, setSelOrigem] = useState('');
    
    const [destinoRet, setDestinoRet] = useState([]); // Array vazio como estado inicial
    const [selDestino, setSelDestino] = useState('');




    const [subReg, setSubReg] = useState('')
    const [selSubReg, setSelSubReg] = useState('')
    const [valFrete, setValFrete] = useState(0)
    const [valFreteVendido, setValFreteVendido] = useState(0)

    const [qtdeEntregaCad, setQtdeEntregaCad] = useState(0)
    const [qtdeEntregaCad2, setQtdeEntregaCad2] = useState(0)

    const [qtdeEntregaCad3, setQtdeEntregaCad3] = useState(0)

    const [roteiro, setRoteiro] = useState('')
   
    const [autCad, setAutCad] = useState('')
    const [autCad2, setAutCad2] = useState('')
    const [autCad3, setAutCad3] = useState('')
    // const [manCad, setManCad] = useState('')
    // const [manCadQtd, setManCadQtd] = useState(0)
    // const [manCad1, setManCad1] = useState('')
    // const [manCadQtd1, setManCadQtd1] = useState(0)
    // const [manCad2, setManCad2] = useState('')
    // const [manCadQtd2, setManCadQtd2] = useState(0)
    // const [manCad3, setManCad3] = useState('')
    // const [manCadQtd3, setManCadQtd3] = useState(0)
    // const [manCad4, setManCad4] = useState('')
    // const [manCadQtd4, setManCadQtd4] = useState(0)
    // const [manCad5, setManCad5] = useState('')
    // const [manCadQtd5, setManCadQtd5] = useState(0)
    // const [manCad6, setManCad6] = useState('')
    // const [manCadQtd6, setManCadQtd6] = useState(0)
    // const [manCad7, setManCad7] = useState('')
    // const [manCadQtd7, setManCadQtd7] = useState(0)
    // const [manCad8, setManCad8] = useState('')
    // const [manCadQtd8, setManCadQtd8] = useState(0)
    // const [manCad9, setManCad9] = useState('')
    // const [manCadQtd9, setManCadQtd9] = useState(0)
    // const [manCad10, setManCad10] = useState('')
    // const [manCadQtd10, setManCadQtd10] = useState(0)
    // const [manCad11, setManCad11] = useState('')
    // const [manCadQtd11, setManCadQtd11] = useState(0)
    const [files, setFiles] = useState('')
    const [mainUsage, setMainUsage] = useState('')
    const [insProdVal, setInsProdVal] = useState('')
    const [formaCadencia, setFormaCadencia] = useState('')
    const [formaCadencia2, setFormaCadencia2] = useState('')
    const [formaCadencia3, setFormaCadencia3] = useState('')
    const [stateUpdate, setStateUpdate] = useState(true)
    const classes = useStyles();
    const [wait, setWait] = useState(false)
    const [permitido, setPermitido] = useState(false)

    const [posEmb, setPosEmb] = useState('')
    const [posEmb2, setPosEmb2] = useState('')
    const [posEmb3, setPosEmb3] = useState('')
    const [procurarCliente, setProcurarCliente] = useState('')
    const [completando, setCompletando] = useState(false)
    const [supervisor, setSupervisor] = useState('')


    const [gerente, setGerente] = useState('')
    const [pedidoMae, setPedidoMae] = useState('')
    const [desmembramento, setDesmembramento] = useState(false)
    const [pedidosSap, setPedidosSap] = useState('')

    const [hierarquia, setHierarquia] = useState('')
    const [colaboradores, setColaboradores] = useState('')

    //const [fiadores, setFiadores] = useState([])
   // const [addFiador, setAddFiador] = useState(false)
// Garantir que origemRet e destinoRet sejam arrays válidos
    const origemOptions = Array.isArray(origemRet)
    ? origemRet.map((option) => ({
        value: option,
        label: option,
    }))
    : [];

    const destinoOptions = Array.isArray(destinoRet)
    ? destinoRet.map((option) => ({
        value: option,
        label: option,
    }))
    : [];


    const vendedorOptions = Object.keys(colaboradores).map((key) => ({
        value: colaboradores[key].nome,
        label: colaboradores[key].nome,
    }));

    const supervisorOptions = Object.keys(colaboradores).map((key) => ({
        value: colaboradores[key].nome,
        label: colaboradores[key].nome,
    }));
    const gerenteOptions = Object.keys(colaboradores).map((key) => ({
        value: colaboradores[key].nome,
        label: colaboradores[key].nome,
    }));

    useEffect(() => {
        if (props.user.user !== '') {
            setVendedor(props.user.user)
        }

        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('vendas', props))

            const response_filiais = await api.get('/Filiais', { params: { userId: getUser(), token: getToken() } })
            setFiliais(response_filiais.data)
            const response_clientes = await api.get("/Clientes", { params: { userId: getUser(), token: getToken(), token2: '63cbse4yu21fw24f2v2' } });
            setClientes(response_clientes.data)
            const response_itens = await api.get("/Itens", { params: { userId: getUser(), token: getToken() } });
            setItens(response_itens.data)
            const respose_dest = await api.get("/returnOr", { params: { userId: getUser(), token: getToken() } })
            setOrigemRet(respose_dest.data)
            const respose_dest2 = await api.get("/returnDes", { params: { userId: getUser(), token: getToken() } })
            setDestinoRet(respose_dest2.data)
            const response_subreg = await api.get('/returnSub', { params: { userId: getUser(), token: getToken() } })
            setSubReg(response_subreg.data)

            const colab = await api.get('/getColaboradoresComercial', { params: { userId: getUser(), token: getToken() } })
            setColaboradores(colab.data)
            const auxSupervisores = colab.data.filter((item) => item.supervisor === 1)
            setSupervisores(auxSupervisores)
            const auxGerentes = colab.data.filter((item) => item.gerente === 1)
            setGerentes(auxGerentes)
            const hiera = await api.get('/getHierarquiaComercial', { params: { userId: getUser(), token: getToken() } })
            // setHierarquia(hiera.data)
            // let idsSup = []
            // auxSupervisores.map((item) => {
            //     idsSup.push(item.id)
            // })
            // setIdSupervisores(idsSup)


            const response_docrate = await api.get('/returnDocRate', { params: { userId: getUser(), token: getToken() } })
            setDocRate(response_docrate.data)
            const response_files = await api.get('/returnFileNames', { params: { userId: getUser(), token: getToken() } })
            setFiles(response_files.data)

            // solução top pra pegar os valores de uma query string
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());

            const response_pedidos = await api.get('listaPedidosVendaSAP', { params: { userId: getUser(), token: getToken() } })
            setPedidosSap(response_pedidos.data.map(i => i.numeroSAP.toString()))


            if (params.pedido !== undefined && params.token !== undefined) {
                const args = await api.get('/getArgsVenda', { params: { 'numPedido': params.pedido, 'tokenVenda': params.token, userId: getUser(), token: getToken() } })

                if (args.data === 'SEM-TOKEN') { return }

                // tive que passar o response_clientes.data porque tinha uma informação que eu só conseguia nele (as opções do primeiro autocomplete "Procurar Cliente")
                // e o state "clientes" ainda é uma string vazia nessa etapa do código
                await autoCompleta(args.data, response_clientes.data)

            }
        }
        fetchMyAPI();
    }, []);

    useEffect(() => {
        if (completando === false) {
            calculaFrete()
        }
    }, [completando])

    async function autoCompleta(args, clienteData) {
        setCompletando(true)
        // compara o cpf do "args" com o cpf do "clienteData" (lista de clientes) para pegar o cliente certo da lista
        const dadosCliente = clienteData.filter((el) => el.CardCode === args.codCliente)[0]
        const identificadorCliente = dadosCliente.identAutoComplete
        setCodCliente(args.codCliente)
        setProcurarCliente(identificadorCliente)
        setNomeCliente(args.cliente)
        setEndEntrega(args.endEntrega)
        setCidadeEntrega(args.cidadeEntrega)
        setEstadoEntrega(args.estadoEntrega)
        setCPF(args.cpf)
        setCEPEntrega(args.cep)
        setFilial(args.filial)
        setRg(args.rg)
        setEmailCliente(args.emailCliente)
        setTelCliente(args.telCliente)

        const aux = dadosCliente.CardCode
        const respose_insc = await api.get('/InsEstadual', { params: { cod: aux, userId: getUser(), token: getToken() } })
        if (respose_insc === 'NOK') { alert('Inscrição estudal do cliente não encontrada!') }
        else {
            setInsProd(respose_insc.data)
        }
        setInsProdVal(args.insProdVal)

        setObserv(args.observ)
        setForma(args.forma)
        setPar1(args.par1)
        setPar2(args.par2)
        setPar3(args.par3)
        setPar4(args.par4)

        setMoeda(args.moeda)
        setMainUsage(args.mainUsage)

        // produto 0
        setQtde(args.qtde)
        setCultura(args.cultura)
        setUtilidade(args.utilidade)
        setNomeProduto(args.nomeProduto)
        setEmb(args.emb)
        setUni(parseFloat(args.uni))

        let cadDatas = args.cadencia.split(';')
        cadDatas.pop()
        let arrayCad = []
        cadDatas.map((item) => {
            item = item.split('#')
            arrayCad.push({ start: item[0], end: item[1], qtde: parseFloat(item[2]) })
        })

        setFormaCadencia(args.formaCadencia)
        setQtdeEntregaCad(args.qtdeEntregaCad)
        setAutCad(arrayCad)

        // produto 1 e 2
        let contador = 0
        if (args.nomeProduto2 !== '') {
            contador += 1
            setQtde2(args.qtde2)
            setCultura2(args.cultura2)
            setUtilidade2(args.utilidade2)
            setNomeProduto2(args.nomeProduto2)
            setEmb2(args.emb2)
            setUni2(parseFloat(args.uni2))

            let cadDatas2 = args.cadencia2.split(';')
            cadDatas2.pop()
            let arrayCad2 = []
            cadDatas2.map((item) => {
                item = item.split('#')
                arrayCad2.push({ start: item[0], end: item[1], qtde: parseFloat(item[2]) })
            })
            setFormaCadencia2(args.formaCadencia2)
            setQtdeEntregaCad2(args.qtdeEntregaCad2)
            setAutCad2(arrayCad2)
        }

        if (args.nomeProduto3 !== '') {
            contador += 1
            setQtde3(args.qtde3)
            setCultura3(args.cultura3)
            setUtilidade3(args.utilidade3)
            setNomeProduto3(args.nomeProduto3)
            setEmb3(args.emb3)
            setUni3(parseFloat(args.uni3))

            let cadDatas3 = args.cadencia3.split(';')
            cadDatas3.pop()
            let arrayCad3 = []
            cadDatas3.map((item) => {
                item = item.split('#')
                arrayCad3.push({ start: item[0], end: item[1], qtde: parseFloat(item[2]) })
            })
            setFormaCadencia3(args.formaCadencia3)
            setQtdeEntregaCad3(args.qtdeEntregaCad3)
            setAutCad3(arrayCad3)
        }
        setCont(contador)
        setSelSubReg(args.selSubReg)

        setIniDate(args.iniDate)
        setEndDate(args.endDate)
        setFrete(args.frete)
        setSelOrigem(args.selOrigem)
        setSelDestino(args.selDestino)
        setRoteiro(args.roteiro)
        setEmail(args.email)
        setPhoneEntrega(args.phone)
        setCaixaPostal(args.caixaPostal)

        setRua(args.rua)
        setCidade(args.cidade)
        setEstado(args.estado)
        setCep(args.cep)

        setContrato(args.contrato)
        setVendedor(args.vendedor)
        setDataCond(args.dataCond)
        setCare(args.care)
        setComprador(args.comprador)
        setCompletando(false)
        setSupervisor(args.supervisor)
        setGerente(args.gerente)
    }

    function amount(item) {
        return item.qtde;
    }

    function sum(prev, next) {
        return prev + next;
    }

    function modCadencia(val, i) {
        let aux = autCad
        if (val === '') { val = 0 }
        aux[i].qtde = parseInt(val)
        setAutCad(aux)
        setStateUpdate(~stateUpdate)
    }

    function modCadencia2(val, i) {
        let aux = autCad2
        if (val === '') { val = 0 }
        aux[i].qtde = parseInt(val)
        setAutCad2(aux)
        setStateUpdate(~stateUpdate)
    }

    function modCadencia3(val, i) {
        let aux = autCad3
        if (val === '') { val = 0 }
        aux[i].qtde = parseInt(val)
        setAutCad3(aux)
        setStateUpdate(~stateUpdate)
    }

    async function calculaFrete() {
        setWait(true)
        let aux = ''
        let val = parseFloat(qtde3) + parseFloat(qtde2) + parseFloat(qtde) - (autCad != '' ? autCad.map(amount).reduce(sum) : 0) - (autCad2 != '' ? autCad2.map(amount).reduce(sum) : 0) - (autCad3 != '' ? autCad3.map(amount).reduce(sum) : 0)

        if (val != 0) {
            alert('Ainda existe ' + val + ' tons de volume para cadênciar!')
            setWait(false)
            return
        }
        Object.keys(autCad).forEach(
            (item) => (aux = aux + autCad[item].start + '#' + autCad[item].end + '#' + autCad[item].qtde + ';')
        )
        let aux2 = ''
        Object.keys(autCad2).forEach(
            (item) => (aux2 = aux2 + autCad2[item].start + '#' + autCad2[item].end + '#' + autCad2[item].qtde + ';')
        )
        let aux3 = ''
        Object.keys(autCad3).forEach(
            (item) => (aux3 = aux3 + autCad3[item].start + '#' + autCad3[item].end + '#' + autCad3[item].qtde + ';')
        )
        const response = await api.get("/returnValFrete", {
            params: {
                origem: selOrigem,
                destino: selDestino,
                subreg: selSubReg,
                cadencia: aux,
                cadencia2: aux2,
                cadencia3: aux3,
                userId: getUser(),
                token: getToken()
            }
        })
        if (response.data === 'NOK') {
            alert('Valor de frete não encontrado, entre em contato com o time de logística')
        }
        else {
            setValFrete(response.data)
            setValFreteVendido(response.data)
        }
        setWait(false)
    }

    async function setaValores(v) {
        if (completando) { return }
        let aux = ''
        setProcurarCliente(v)
        for (var i = 0; i < clientes.length; i++) {
            // look for the entry with a matching `code` value
            if (clientes[i].identAutoComplete === v) {
                setCodCliente(clientes[i].CardCode)
                aux = clientes[i].CardCode
                setNomeCliente(clientes[i].CardName)
                setComprador(clientes[i].CardName)
                setCPF(clientes[i].CPF)
                setEmailCliente(clientes[i].E_mail)
                setTelCliente(clientes[i].Phone1)
                setRua(clientes[i].Address)
                setCep(clientes[i].CEP)
                setTel(clientes[i].Phone1)
                setInsProd('')
                setCidade(clientes[i].City)
                setEstado(clientes[i].State1)
                const respose_insc = await api.get('/InsEstadual', { params: { cod: aux, userId: getUser(), token: getToken() } })
                if (respose_insc === 'NOK') { alert('Inscrição estudal do cliente não encontrada!') }
                else {
                    setInsProd(respose_insc.data)
                }
            }
        }

    }
    //
    async function geraPlanilha() {
        let val1 = parseFloat(qtde3) + parseFloat(qtde2) + parseFloat(qtde)
        
        if (new Date(dataCond) < new Date(new Date().setDate(new Date().getDate() - 1))) {
            alert('Vencimento Retroativo!')
            return
        }
        // let val2 = parseFloat(manCadQtd) + parseFloat(manCadQtd1) + parseFloat(manCadQtd2) + parseFloat(manCadQtd3) + parseFloat(manCadQtd4) + parseFloat(manCadQtd5) + parseFloat(manCadQtd6) + parseFloat(manCadQtd7)
        // if (val1 > val2 || val1 < val2) {
        //     alert('Valor Cadênciado diferente da quantidade do pedido \n' + 'valorPedido :' + val1 + '\n' + 'Valor Cadênciado :' + val2)
        //     return
        // }
        let aux = ''
        Object.keys(autCad).forEach(
            (item) => (aux = aux + autCad[item].start + '#' + autCad[item].end + '#' + autCad[item].qtde + ';')
        )
        let aux2 = ''
        Object.keys(autCad2).forEach(
            (item) => (aux2 = aux2 + autCad2[item].start + '#' + autCad2[item].end + '#' + autCad2[item].qtde + ';')
        )
        let aux3 = ''
        Object.keys(autCad3).forEach(
            (item) => (aux3 = aux3 + autCad3[item].start + '#' + autCad3[item].end + '#' + autCad3[item].qtde + ';')
        )

        let validacao =
            (valFrete === 0 && frete == 'CIF' ? 'Valor Frete\n' : '') +
            (selDestino === '' && frete == 'CIF' ? 'Destino Logistica\n' : '') +
            (filial === '' ? 'Filial\n' : '') +
            (cliente === '' ? 'Cliente\n' : '') +
            (forma === '' ? 'Forma de Pagamento\n' : '') +
            (nomeProduto === '' ? 'Nome Do Produto\n' : '') +
            (codProduto === '' ? 'Código do Produto\n' : '') +
            (qtde === '' ? 'Quantidade\n' : '') +
            (emb === '' ? 'Embalagem\n' : '') +
            (uni === '' ? 'Preço Unitário\n' : '') +
            (iniDate === '' ? 'Inicial Entrega\n' : '') +
            (endDate === '' ? 'Final Entrega\n' : '') +
            (frete === '' ? 'Tipo Frete\n' : '') +
            (observ === '' ? 'Observações\n' : '') +
            (endEntrega === '' ? 'Endereço Entrega\n' : '') +
            (cidadeEntrega === '' ? 'Cidade Entrega\n' : '') +
            (estadoEntrega === '' ? 'Estado Entrega\n' : '') +
            (CEPEntrega === '' ? 'CEP Entrega\n' : '') +
            (email === '' ? 'e-mail\n' : '') +
            (phone === '' ? 'Telefone\n' : '') +
            (caixaPostal === '' ? 'Caixa Postal\n' : '') +
            (care === '' ? 'Responsável\n' : '') +
            (dataCond === '' ? 'Data Condição\n' : '') +
            (vendedor === '' ? 'Vendedor\n' : '') +
            (supervisor === '' ? 'Supervisor\n' : '') +
            (gerente === '' ? 'Gerente\n' : '') +
            (comprador === '' ? 'Comprador\n' : '') +
            (valFreteVendido === '' && frete !== 'FOB '? 'Valor Frete Vendido\n' : '') +
            (desmembramento && pedidoMae === '' ? 'Pedido Mãe\n' : '')
           

            

        if (validacao.length !== 0) {
            alert('Alguns campos não foram preenchidos! \n' + validacao)
            return
        }

        const response_sap = await api.get("/AdicionaVendaAprovacao", {
            params: {
                filial,
                cliente,
                codCliente,
                insProdVal,
                cpf,
                rg,
                rua,
                cidade,
                estado,
                cep,
                emailCliente,
                telCliente,
                tel,
                agencia,
                banco,
                conta,
                forma,
                par1,
                par2,
                par3,
                par4,
                nomeProduto,
                codProduto,
                cultura,
                utilidade,
                qtde,
                emb,
                uni,
                nomeProduto2,
                codProduto2,
                cultura2,
                utilidade2,
                qtde2,
                emb2,
                uni2,
                nomeProduto3,
                codProduto3,
                cultura3,
                utilidade3,
                qtde3,
                emb3,
                uni3,
                iniDate,
                endDate,
                frete,
                observ,
                endEntrega,
                cidadeEntrega,
                estadoEntrega,
                CEPEntrega,
                email,
                phone,
                caixaPostal,
                contrato,
                care,
                dataCond,
                vendedor,
                comprador,
                totProd3: uni3 * qtde3,
                totProd2: uni2 * qtde2,
                totProd1: uni * qtde,
                moeda,
                docRate,
                valFrete,
                valFreteVendido,
                roteiro,
                cadencia: aux,
                cadencia2: aux2,
                cadencia3: aux3,
                userLog: getUser(), mainUsage, selDestino, selOrigem, supervisor, gerente,

                formaCadencia,
                formaCadencia2,
                formaCadencia3,
                qtdeEntregaCad,
                qtdeEntregaCad2,
                qtdeEntregaCad3,
                selSubReg,
                pedidoMae,
                desmembramento,
                'fiadores': '' ,//movido para a outra tela
                
                userId: getUser(),
                token: getToken()
            },
        });
        if (response_sap.data === 'NOK') {
            alert('Erro ao enviar pedido')
        } else {
            alert('Pedido Enviado para aprovação com sucesso!')
            window.location.reload();
        }
        setWait(false)
    }

    const setaValoresItem = (v, tipo, num) => {
        for (var i = 0; i < itens.length; i++) {
            if (tipo === 'nome') {
                if (itens[i].ItemName === v) {
                    if (num === 1) {
                        setNomeProduto(itens[i].ItemName)
                        setCodigProduto(itens[i].itemCode)
                        if ((parseInt(itens[i].u_next_ntotal) > 6) & (itens[i].itemCode.includes('MP'))) {
                            setPosEmb([{ name: 'SACARIA' }, { name: 'BB c/Liner' }, { name: 'GRANEL' }])
                        } else if ((parseInt(itens[i].u_next_ntotal) > 0) & (itens[i].itemCode.includes('PA'))) {
                            setPosEmb([{ name: 'SACARIA' }, { name: 'BB c/Liner' }, { name: 'GRANEL' }])
                        } else {
                            setPosEmb([{ name: 'SACARIA' }, { name: 'GRANEL' }, { name: 'BB c/Liner' }, { name: 'BB s/Liner' }])
                        }
                    } else if (num === 2) {
                        setNomeProduto2(itens[i].ItemName)
                        setCodigProduto2(itens[i].itemCode)
                        if ((parseInt(itens[i].u_next_ntotal) > 6) & (itens[i].itemCode.includes('MP'))) {
                            setPosEmb2([{ name: 'SACARIA' }, { name: 'BB c/Liner' }, { name: 'GRANEL' }])
                        } else if ((parseInt(itens[i].u_next_ntotal) > 0) & (itens[i].itemCode.includes('PA'))) {
                            setPosEmb2([{ name: 'SACARIA' }, { name: 'BB c/Liner' }, { name: 'GRANEL' }])
                        } else {
                            setPosEmb2([{ name: 'SACARIA' }, { name: 'GRANEL' }, { name: 'BB c/Liner' }, { name: 'BB s/Liner' }])
                        }
                    } else if (num === 3) {
                        setNomeProduto3(itens[i].ItemName)
                        setCodigProduto3(itens[i].itemCode)
                        if ((parseInt(itens[i].u_next_ntotal) > 6) & (itens[i].itemCode.includes('MP'))) {
                            setPosEmb3([{ name: 'SACARIA' }, { name: 'BB c/Liner' }, { name: 'GRANEL' }])
                        } else if ((parseInt(itens[i].u_next_ntotal) > 0) & (itens[i].itemCode.includes('PA'))) {
                            setPosEmb3([{ name: 'SACARIA' }, { name: 'BB c/Liner' }, { name: 'GRANEL' }])
                        } else {
                            setPosEmb3([{ name: 'SACARIA' }, { name: 'GRANEL' }, { name: 'BB c/Liner' }, { name: 'BB s/Liner' }])
                        }
                    }
                }
            } else if (tipo === 'codigo') {
                if (itens[i].itemCode === v) {

                    if (num === 1) {


                        setNomeProduto(itens[i].ItemName)
                        setCodigProduto(itens[i].itemCode)
                    } else if (num === 2) {
                        setNomeProduto2(itens[i].ItemName)
                        setCodigProduto2(itens[i].itemCode)
                    } else if (num === 3) {
                        setNomeProduto3(itens[i].ItemName)
                        setCodigProduto3(itens[i].itemCode)
                    }
                }
            }
        }
    }

    function adicionaZero(numero) {
        if (numero <= 9)
            return "0" + numero;
        else
            return numero;
    }

    const handleChangeCad = (event) => {
        setAutCad('')
        setFormaCadencia(event.target.value);
    };

    const handleChangeCad2 = (event) => {
        setAutCad2('')
        setFormaCadencia2(event.target.value);
    };

    const handleChangeCad3 = (event) => {
        setAutCad3('')
        setFormaCadencia3(event.target.value);
    };

    async function generateCad() {
        if (formaCadencia === '') {
            alert('Informe o formato da cadência!')
            return
        }
        const response_cad = await api.get('/returnCadencia', {
            params: {
                qtdeTotal: (parseFloat(qtde)),
                qtdeEntregaCad,
                formaCadencia,
                iniDate,
                endDate,
                userId: getUser(),
                token: getToken()
            }
        })
        if (response_cad.data === "NOK") { alert('Erro ao gerar cadência, verifique os valores') }
        else {
            setAutCad('')
            setAutCad(response_cad.data)
        }
    }

    async function generateCad2() {
        if (formaCadencia2 === '') {
            alert('Informe o formato da cadência!')
            return
        }
        const response_cad = await api.get('/returnCadencia', {
            params: {
                qtdeTotal: (parseFloat(qtde2)),
                'qtdeEntregaCad': qtdeEntregaCad2,
                'formaCadencia': formaCadencia2,
                iniDate,
                endDate,
                userId: getUser(),
                token: getToken()
            }
        })
        if (response_cad.data === "NOK") { alert('Erro ao gerar cadência, verifique os valores') }
        else {
            setAutCad2('')
            setAutCad2(response_cad.data)
        }
    }

    async function generateCad3() {
        if (formaCadencia3 === '') {
            alert('Informe o formato da cadência!')
            return
        }
        const response_cad = await api.get('/returnCadencia', {
            params: {
                qtdeTotal: (parseFloat(qtde3)),
                'qtdeEntregaCad': qtdeEntregaCad3,
                'formaCadencia': formaCadencia3,
                iniDate,
                endDate,
                userId: getUser(),
                token: getToken()
            }
        })
        if (response_cad.data === "NOK") { alert('Erro ao gerar cadência, verifique os valores') }
        else {
            setAutCad3('')
            setAutCad3(response_cad.data)
        }
    }

    //Funções somar Cadência
    async function puxaValoresInsEst(v) {
        if (completando) { return }
        setInsProdVal(v)
        const respose_insc = await api.get('/DadosInsEst', { params: { ins: v, userId: getUser(), token: getToken() } })
        setEndEntrega(respose_insc.data[0].Address)
        setCidadeEntrega(respose_insc.data[0].city)
        setEstadoEntrega(respose_insc.data[0].State)
        setCEPEntrega(respose_insc.data[0].ZipCode)
        //setEstadoEntrega(insProd.State)
    }

    function handleSetVendedor(valor) {
        setVendedor(valor)
        var isSup = supervisores.some(function (el) {
            return (el.nome == valor);
        });
        if (isSup) {
            setSupervisor(valor)
        }
        var isGer = gerentes.some(function (el) {
            return (el.nome == valor);
        });
        if (isGer) {
            setSupervisor(valor)
            setGerente(valor)
        }
        //if (valor)
        // let id = valor.split(' - ')
        // id = parseInt(id[id.length - 1])

        // if (idSupervisores.includes(id)) {
        //     setSupervisor(valor)
        // } else {
        //     let aux = hierarquia.filter((item) => item.id === id)

        //     if (aux.length === 0) {
        //         setSupervisor('')
        //     } else {
        //         let parentId = aux[0].parentId
        //         let aux2 = supervisores.filter((item) => item.id === parentId)
        //         if (aux2.length !== 0) {
        //             setSupervisor(aux2[0].nome + ' - ' + aux2[0].id)
        //         }
        //     }
        // }
    }
    function handleSetSupervisor(valor) {
        setSupervisor(valor)
        var isGer = gerentes.some(function (el) {
            return (el.nome == valor);
        });
        if (isGer) {
            setGerente(valor)
        }
    }

    // cria um array de objetos com os fiadores, caso o usuário tenha escolhi 2 ou 4, ele adiciona
    // caso o usuário tenha escolhido 4 e diminuido para 2, ele remove os ultimos 2 objetos do array
    // const createFiadores = (qtd) => {
    //     qtd = parseInt(qtd)
    //     let aux = [...fiadores]

    //     if (qtd > fiadores.length) {
    //         for (let index = fiadores.length; index < qtd; index++) {
    //             aux.push({ nome: '', cpf: '', cidadeEstado: '', rua: '', bairro: '', cep: '', numeroCasa: '' })
    //         }
    //     } else {
    //         aux.pop()
    //         aux.pop()
    //     }
    //     setFiadores(aux)
    // }

    // const updateFiador = (index, valor, key) => {
    //     let aux = [...fiadores]
    //     aux[index][key] = valor
    //     setFiadores(aux)
    // }

    return (
        permitido === true ?
            <div>
                <div className="card">
                    <div className="card-body">
                        {/* Grid container para Cliente e Filial */}
                        <div className="row">
                            {/* Coluna para o campo de Cliente */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="cliente-select" className="form-label">Cliente</label>
                                {clientes !== '' ? (
                                    <Autocomplete
                                    id="combo-box-demo"
                                    options={Object.keys(clientes).map((item) => clientes[item].identAutoComplete)}
                                    onChange={(e, v) => setaValores(v)}
                                    getOptionSelected={(option, value) => option === value || value === ''}
                                    value={procurarCliente}
                                    renderInput={(params) => (
                                        <TextField
                                            value={procurarCliente}
                                            {...params}
                                            variant='outlined'
                                            label='Procurar Cliente'
                                        />
                                    )}
                                />
                                ) : (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: "40px" }}>
                                        <Loader
                                            type="Circles"
                                            color="#00BFFF"
                                            height={20}
                                            width={20}
                                            timeout={2000000} //3 secs
                                        />
                                        <span style={{ marginLeft: "10px" }}>Carregando...</span>
                                    </div>
                                )}
                            </div>

                            {/* Coluna para o campo de Filial */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="filial-select" className="form-label">Filial</label>
                                {filiais !== '' ? (
                                    <Autocomplete
                                    id="combo-box-demo"
                                    options={Object.keys(filiais).map((item) => filiais[item].BPLName)}
                                    onChange={(e, v) => setFilial(v)}
                                    value={filial}
                                    getOptionSelected={(option, value) => option === value || value === ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            value={filial}
                                            label="Filial"
                                            variant='outlined'
        
                                        />
                                    )}
                                />
                                ) : (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: "40px" }}>
                                        <Loader
                                            type="Circles"
                                            color="#00BFFF"
                                            height={20}
                                            width={20}
                                            timeout={2000000} //3 secs
                                        />
                                        <span style={{ marginLeft: "10px" }}>Carregando...</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Seção de desmembramento */}

                        <div className="border-top pt-3">
                            <label className="form-label">Desmembramento:</label>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => setDesmembramento(!desmembramento)}
                            />

                            {desmembramento ? (
                                <>
                                    {pedidosSap !== '' ? (
                                        <Select
                                            id="pedido-mae-select"
                                            className="basic-single"
                                            classNamePrefix="select"
                                            options={pedidosSap.map((pedido) => ({
                                                value: pedido,
                                                label: pedido,
                                            }))}
                                            value={
                                                pedidoMae
                                                    ? { value: pedidoMae, label: pedidoMae }
                                                    : null
                                            }
                                            onChange={(option) =>
                                                setPedidoMae(option ? option.value : '')
                                            }
                                            placeholder="Digite número do Pedido Mãe"
                                            isClearable
                                            isSearchable // Ativa o campo de busca no select
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    width: '100%',
                                                }),
                                            }}
                                        />
                                    ) : (
                                        <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{ height: '40px' }}
                                        >
                                            <Loader
                                                type="Circles"
                                                color="#00BFFF"
                                                height={20}
                                                width={20}
                                                timeout={2000000}
                                            />
                                            <span style={{ marginLeft: '10px' }}>Carregando...</span>
                                        </div>
                                    )}
                                </>
                            ) : null}
                        </div>                    
                    </div>
                </div>


                <GridContainer>

                <div className="container-fluid" style={{ marginTop: "20px" }}>
                    <div className="card w-100">

                        <div className="card-header text-white" style={{ backgroundColor: "#012240" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <PersonIcon style={{ fontSize: "24px", marginRight: "10px" }} />
                                <div>
                                <h4 className="fw-bold mb-0">Cliente</h4>
                                <p className="mb-0 fs-6">Dados do Cliente</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row mb-3">
                                {/* Nome/Razão Social */}
                                <div className="col-md-6">
                                    <label htmlFor="nomeCliente" className="form-label">Nome/Razão Social</label>
                                    <input
                                        type="text"
                                        id="nomeCliente"
                                        className="form-control"
                                        value={cliente}
                                        onChange={(e) => setNomeCliente(e.target.value)}
                                        required
                                    />
                                </div>
                                {/* CPF/CNPJ */}
                                <div className="col-md-3">
                                    <label htmlFor="cpf" className="form-label">CPF/CNPJ</label>
                                    <input
                                        type="text"
                                        id="cpf"
                                        className="form-control"
                                        value={cpf}
                                        onChange={(e) => setCPF(e.target.value)}
                                        readOnly
                                        required
                                    />
                                </div>
                                {/* RG/I.E */}
                                <div className="col-md-3">
                                    <label htmlFor="rg" className="form-label">RG/I.E</label>
                                    <input
                                        type="text"
                                        id="rg"
                                        className="form-control"
                                        value={rg}
                                        onChange={(e) => setRg(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                {/* Inscrição Estadual Produtor */}
                                <div className="col-md-6">
                                    <label htmlFor="inscricaoProdutor" className="form-label">Inscrição Estadual Produtor</label>
                                    {insProd !== '' ? (
                                        Object.keys(insProd).length > 0 ? (
                                            <select
                                                id="inscricaoProdutor"
                                                className="form-select"
                                                value={insProdVal ? insProdVal.TaxId1 : ''}
                                                onChange={(e) => puxaValoresInsEst(e.target.value)}
                                            >
                                                <option value="">Selecione</option>
                                                {Object.keys(insProd).map((key) => (
                                                    <option key={key} value={insProd[key].TaxId1}>
                                                        {`${insProd[key].TaxId1} - ${insProd[key].Address}`}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <p className="text-muted">Nenhuma inscrição cadastrada!</p>
                                        )
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center" style={{ height: "40px" }}>
                                            <Loader
                                                type="Circles"
                                                color="#00BFFF"
                                                height={20}
                                                width={20}
                                                timeout={200000} // 3 secs
                                            />
                                            <span className="ms-2">Carregando...</span>
                                        </div>
                                    )}
                                </div>
                                {/* Endereço */}
                                <div className="col-md-12">
                                    <label htmlFor="endEntrega" className="form-label">ENDEREÇO (Rua, Av., Sitio, Fazenda)</label>
                                    <input
                                        type="text"
                                        id="endEntrega"
                                        className="form-control"
                                        value={endEntrega}
                                        onChange={(e) => setEndEntrega(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                {/* Cidade */}
                                <div className="col-md-3">
                                    <label htmlFor="cidadeEntrega" className="form-label">Cidade</label>
                                    <input
                                        type="text"
                                        id="cidadeEntrega"
                                        className="form-control"
                                        value={cidadeEntrega}
                                        onChange={(e) => setCidadeEntrega(e.target.value)}
                                        required
                                    />
                                </div>
                                {/* Estado */}
                                <div className="col-md-3">
                                    <label htmlFor="estadoEntrega" className="form-label">Estado</label>
                                    <input
                                        type="text"
                                        id="estadoEntrega"
                                        className="form-control"
                                        value={estadoEntrega}
                                        onChange={(e) => setEstadoEntrega(e.target.value)}
                                        required
                                    />
                                </div>
                                {/* CEP */}
                                <div className="col-md-3">
                                    <label htmlFor="cepEntrega" className="form-label">CEP</label>
                                    <input
                                        type="text"
                                        id="cepEntrega"
                                        className="form-control"
                                        value={CEPEntrega}
                                        onChange={(e) => setCEPEntrega(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                {/* Observações */}
                                <div className="col-md-5">
                                    <label htmlFor="observacoes" className="form-label">Observações</label>
                                    <textarea
                                        id="observacoes"
                                        className="form-control"
                                        rows="3"
                                        value={observ}
                                        onChange={(e) => setObserv(e.target.value)}
                                    ></textarea>
                                </div>
                                {/* Email */}
                                <div className="col-md-4">
                                    <label htmlFor="emailCliente" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        id="emailCliente"
                                        className="form-control"
                                        value={emailCliente}
                                        onChange={(e) => setEmailCliente(e.target.value)}
                                        required
                                    />
                                </div>
                                {/* Telefone */}
                                <div className="col-md-3">
                                    <label htmlFor="telCliente" className="form-label">Tel</label>
                                    <input
                                        type="text"
                                        id="telCliente"
                                        className="form-control"
                                        value={telCliente}
                                        onChange={(e) => setTelCliente(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    

                    {/* Dados Pagamento */}
                    <div className="container-fluid" style={{ marginTop: "20px" }}>
                        <div className="card w-100">
                        <div className="card-header text-white" style={{ backgroundColor: "#012240" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <PaymentIcon style={{ fontSize: "24px", marginRight: "10px" }} />
                                <div>
                                <h4 className="fw-bold mb-1">Pagamento</h4>
                                <p className="mb-0 fs-6">Dados do Pagamento</p>
                                </div>
                            </div>
                        </div>
                            <div className="card-body">
                                <div className="row mb-3">
                                    {/* Agência */}
                                    <div className="col-md-4">
                                        <label htmlFor="agencia" className="form-label">Agência</label>
                                        <input
                                            type="text"
                                            id="agencia"
                                            className="form-control"
                                            value={agencia}
                                            onChange={(e) => setAgencia(e.target.value)}
                                            readOnly
                                        />
                                    </div>
                                    {/* Banco */}
                                    <div className="col-md-4">
                                        <label htmlFor="banco" className="form-label">Banco</label>
                                        <input
                                            type="text"
                                            id="banco"
                                            className="form-control"
                                            value={banco}
                                            onChange={(e) => setBanco(e.target.value)}
                                            readOnly
                                        />
                                    </div>
                                    {/* Conta Corrente */}
                                    <div className="col-md-4">
                                        <label htmlFor="conta" className="form-label">Conta Corrente</label>
                                        <input
                                            type="text"
                                            id="conta"
                                            className="form-control"
                                            value={conta}
                                            onChange={(e) => setConta(e.target.value)}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                {/* Forma de Pagamento */}
                                <div className="row mb-3">
                                    <div className="col-md-12">
                                        <label htmlFor="forma" className="form-label">Forma de Pagamento</label>
                                        <select
                                            id="forma"
                                            className="form-select"
                                            value={forma}
                                            onChange={(e) => setForma(e.target.value)}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="À Vista">À Vista</option>
                                            <option value="À Prazo">À Prazo</option>
                                            <option value="Parcelado">Parcelado</option>
                                        </select>
                                    </div>
                                </div>

                                {/* Parcelas */}
                                {forma !== 'À Vista' && (
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label htmlFor="parcela1" className="form-label">Parcela 1</label>
                                            <input
                                                type="date"
                                                id="parcela1"
                                                className="form-control"
                                                value={par1}
                                                onChange={(e) => setPar1(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="parcela2" className="form-label">Parcela 2</label>
                                            <input
                                                type="date"
                                                id="parcela2"
                                                className="form-control"
                                                value={par2}
                                                onChange={(e) => setPar2(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="parcela3" className="form-label">Parcela 3</label>
                                            <input
                                                type="date"
                                                id="parcela3"
                                                className="form-control"
                                                value={par3}
                                                onChange={(e) => setPar3(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="parcela4" className="form-label">Parcela 4</label>
                                            <input
                                                type="date"
                                                id="parcela4"
                                                className="form-control"
                                                value={par4}
                                                onChange={(e) => setPar4(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/*info Produto*/}
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{ backgroundColor: '#012240' }}>
                                <h4 className={classes.cardTitleWhite}>Produtos</h4>
                                <p className={classes.cardCategoryWhite}>Dados do Produtos</p>
                            </CardHeader>
                            <CardBody>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <p> Data Inicio Entrega</p>
                                        <TextField type="date" style={{ width: 250 }} value={iniDate} onChange={(e) => setIniDate(e.target.value)} />


                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <p> Data Fim Entrega</p>
                                        <TextField type="date" style={{ width: 250 }} value={endDate} onChange={(e) => setEndDate(e.target.value)} />

                                    </GridItem>
                                </GridContainer>

                                <GridContainer >

                                    <GridItem xs={12} sm={12} md={2} style={{ marginTop: 30 }}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={["R$", "USD"]}
                                            style={{ marginBottom: 15 }}
                                            onChange={(e, v) => setMoeda(v)}
                                            value={moeda}
                                            getOptionSelected={(option, value) => option === value || value === ''}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    label="Moeda"
                                                />
                                            )}
                                        />
                                    </GridItem >
                                    {moeda === 'USD' ?
                                        <GridItem xs={12} sm={12} md={2} style={{ marginTop: 30 }}>
                                            <TextField style={{ width: 150 }} value={docRate} label='Valor Dolar' />
                                        </GridItem>
                                        : <div></div>}
                                    <GridItem xs={12} sm={12} md={2} style={{ marginTop: 30 }}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={["Venda Prod Própria", "Venda Matéria Prima", 'Bonificação Saída', 'Venda Conta Ordem']}
                                            value={mainUsage}
                                            getOptionSelected={(option, value) => option === value || value === ''}
                                            style={{ marginBottom: 15 }}
                                            onChange={(e, v) => setMainUsage(v)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    label="Uso Principal"
                                                />
                                            )}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <div style={{ border: 'solid 1px rgba(0,0,0, .4)', borderRadius: 4, margin: 10, padding: 20, width: '100%' }}>
                                        <GridContainer> {/* Produto 0*/}
                                            <GridItem xs={12} sm={12} md={2}>
                                                <TextField
                                                    value={qtde}
                                                    style={{ width: 175, marginTop: 12 }}
                                                    type="number" label='Quantidade'
                                                    onChange={(e) => setQtde(e.target.value)} />
                                            </GridItem>
                                            
                                            <GridItem xs={12} sm={12} md={2}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={['Soja', 'Milho', 'Cana', 'Algodao', 'Pastagem', 'Feijão', 'Arroz', 'Outros']}
                                                    value={cultura}
                                                    style={{ width: 175, marginTop: 12 }}
                                                    onChange={(e, v) => setCultura(v)}
                                                    getOptionSelected={(option, value) => option === value || value === ''}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            label='Cultura'
                                                        />
                                                    )}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={2}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={['Safra', 'Safrinha']}
                                                    value={utilidade}
                                                    getOptionSelected={(option, value) => option === value || value === ''}
                                                    style={{ width: 175, marginTop: 12 }}
                                                    onChange={(e, v) => setUtilidade(v)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            label='Utilidade'
                                                        />
                                                    )}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                {itens !== '' ? <Autocomplete
                                                    id="combo-box-demo"
                                                    options={Object.keys(itens).map((item) => itens[item].ItemName)}
                                                    value={nomeProduto}

                                                    getOptionSelected={(option, value) => option === value || value === ''}
                                                    style={{ width: 333, marginTop: 12 }}
                                                    onInputChange={(e, v) => setaValoresItem(v, 'nome', 1)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label='Nome Produto'
                                                            multiline
                                                        />
                                                    )}
                                                /> : <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                    timeout={2000000} //3 secs

                                                />}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={2}>
                                                {posEmb !== '' ? <Autocomplete
                                                    id="combo-box-demo"
                                                    options={Object.keys(posEmb).map((item) => posEmb[item].name)}
                                                    value={emb}
                                                    getOptionSelected={(option, value) => option === value || value === ''}

                                                    style={{ width: 175, marginTop: 12 }}
                                                    onChange={(e, v) => setEmb(v)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            label='Embalagem'


                                                        />
                                                    )}
                                                /> : <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={175}
                                                    timeout={2000000} //3 secs

                                                />}


                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={2}>
                                                <TextField
                                                    value={uni}
                                                    style={{ width: 150, marginTop: 12 }}
                                                    type="number"
                                                    label='Valor Unitário'
                                                    onChange={(e) => setUni(e.target.value)} />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={2}>
                                                <TextField style={{ width: 150, marginTop: 12 }} value={parseInt(uni) * parseInt(qtde)} InputProps={{
                                                    readOnly: true,
                                                }} label='Valor Total 1' />
                                            </GridItem>



                                        </GridContainer>
                                        {/* Cadencia Produto 1 */}
                                        <div>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={15}>
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4> Total ton restante entregar Produto 1 -{">"}  {parseFloat(qtde) - (autCad != '' ? autCad.map(amount).reduce(sum) : 0)}</h4>
                                                    </div>
                                                    <div style={{ marginTop: 20 }}>
                                                        <h4> Cadência</h4>
                                                        <TextField style={{ width: 200 }} onChange={(e) => setQtdeEntregaCad(e.target.value)} value={qtdeEntregaCad} label='Quantidade por Entrega' />
                                                        <RadioGroup row aria-label="FormaCadencia" name="FormaCadencia" value={formaCadencia} onChange={handleChangeCad}>
                                                            <FormControlLabel value="Diário" control={<Radio />} label="Diário" />
                                                            <FormControlLabel value="Semanal" control={<Radio />} label="Semanal" />
                                                            <FormControlLabel value="Mensal" control={<Radio />} label="Mensal" />
                                                        </RadioGroup>
                                                    </div>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={15}>
                                                    <Button style={{ backgroundColor: '#01325f', marginTop: 30 }} variant="contained" onClick={() => generateCad()}> Gerar Cadência</Button>


                                                </GridItem>
                                            </GridContainer>


                                            {autCad != '' ?

                                                <div style={{ display: 'flex', flexDirection: 'column', border: "1px solid ", width: 700, borderRadius: 5, overflow: 'auto', height: 400 }} >
                                                    <div style={{ display: 'flex', flexDirection: 'row', width: 250 }}>
                                                        <GridItem style={{ height: 50 }}>
                                                            <p style={{ width: 250 }} >{formaCadencia}</p>
                                                        </GridItem>
                                                        <GridItem style={{ height: 50 }}>
                                                            <p style={{ width: 250 }} >Quantidade</p>
                                                        </GridItem >
                                                    </div>

                                                    {Object.keys(autCad).map((x, i) =>
                                                        <div key={i} style={{ display: 'flex', flexDirection: 'row', width: 700, backgroundColor: i % 2 != 0 ? 'lightGray' : 'white' }}>
                                                            <GridItem style={{ height: 50 }}>
                                                                {formaCadencia !== "Diário" ?
                                                                    <p style={{ width: 250 }} >{new Date(autCad[i].start).toLocaleDateString('pt-br')} -{">"} {new Date(autCad[i].end).toLocaleDateString('pt-br')}</p>
                                                                    : <p style={{ width: 250 }} >{new Date(autCad[i].start).toLocaleDateString('pt-br')}</p>}
                                                            </GridItem>
                                                            <GridItem style={{ height: 50 }}>
                                                                <TextField type="number" style={{ width: 100 }} value={autCad[i].qtde === undefined ? 0 : autCad[i].qtde} InputProps={{ inputProps: { min: 0, max: parseFloat(qtde) } }} onChange={(e) => modCadencia(e.target.value, i)} />
                                                            </GridItem >
                                                        </div>
                                                    )}</div>
                                                : <div />}


                                        </div >
                                    </div >
                                </GridContainer >

                                {cont >= 1 ?
                                    <GridContainer>
                                        <div style={{ border: 'solid 1px rgba(0,0,0, .4)', borderRadius: 4, margin: 10, padding: 20, width: '100%' }}>
                                            <GridContainer> {/* Produto 0*/}
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <TextField
                                                        value={qtde2}
                                                        style={{ width: 175, marginTop: 12 }}
                                                        type="number"
                                                        label='Quantidade'
                                                        onChange={(e) => setQtde2(e.target.value)} />
                                                </GridItem>
                                                {posEmb2 !== '' ? <Autocomplete
                                                    id="combo-box-demo"
                                                    options={Object.keys(posEmb2).map((item) => posEmb2[item].name)}
                                                    getOptionSelected={(option, value) => option === value || value === ''}
                                                    value={emb2}
                                                    style={{ width: 175, marginTop: 12 }}
                                                    onChange={(e, v) => setEmb2(v)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            label='Embalagem'


                                                        />
                                                    )}
                                                /> : <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={175}
                                                    timeout={2000000} //3 secs

                                                />}
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={['Soja', 'Milho', 'Cana', 'Algodao', 'Pastagem', 'Feijão', 'Arroz', 'Outros']}
                                                        value={cultura2}
                                                        style={{ width: 175, marginTop: 12 }}
                                                        getOptionSelected={(option, value) => option === value || value === ''}
                                                        onChange={(e, v) => setCultura2(v)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                required
                                                                label='Cultura'
                                                            />
                                                        )}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={['Safra', 'Safrinha']}
                                                        value={utilidade2}
                                                        getOptionSelected={(option, value) => option === value || value === ''}
                                                        style={{ width: 175, marginTop: 12 }}
                                                        onChange={(e, v) => setUtilidade2(v)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                required
                                                                label='Utilidade'
                                                            />
                                                        )}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    {itens !== '' ? <Autocomplete
                                                        id="combo-box-demo"
                                                        options={Object.keys(itens).map((item) => itens[item].ItemName)}

                                                        getOptionSelected={(option, value) => option === value || value === ''}
                                                        value={nomeProduto2}
                                                        style={{ width: 333 }}
                                                        onInputChange={(e, v) => setaValoresItem(v, 'nome', 2)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label='Nome Produto'
                                                                multiline
                                                            />
                                                        )}
                                                    /> : <Loader
                                                        type="Circles"
                                                        color="#00BFFF"
                                                        height={20}
                                                        width={20}
                                                        timeout={2000000} //3 secs

                                                    />}
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <TextField
                                                        value={uni2}
                                                        style={{ width: 150, marginTop: 12 }}
                                                        type="number"
                                                        label='Valor Unitário'
                                                        onChange={(e) => setUni2(e.target.value)} />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <TextField style={{ width: 150, marginTop: 12 }} value={parseInt(uni2) * parseInt(qtde2)} InputProps={{
                                                        readOnly: true,
                                                    }} label='Valor Total 2' />
                                                </GridItem>
                                            </GridContainer>
                                            {/* Cadencia Produto 2 */}
                                            <div>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={15}>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h4> Total ton restante entregar Produto 2 -{">"}  {parseFloat(qtde2) - (autCad2 != '' ? autCad2.map(amount).reduce(sum) : 0)}</h4>
                                                        </div>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h4> Cadência</h4>
                                                            <TextField style={{ width: 200 }} onChange={(e) => setQtdeEntregaCad2(e.target.value)} value={qtdeEntregaCad2} label='Quantidade por Entrega' />
                                                            <RadioGroup row aria-label="FormaCadencia" name="FormaCadencia" value={formaCadencia2} onChange={handleChangeCad2}>
                                                                <FormControlLabel value="Diário" control={<Radio />} label="Diário" />
                                                                <FormControlLabel value="Semanal" control={<Radio />} label="Semanal" />
                                                                <FormControlLabel value="Mensal" control={<Radio />} label="Mensal" />
                                                            </RadioGroup>
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={15}>
                                                        <Button style={{ backgroundColor: '#012240', marginTop: 30 }} variant="contained" onClick={() => generateCad2()}> Gerar Cadência</Button>


                                                    </GridItem >
                                                </GridContainer >


                                                {autCad2 != '' ?

                                                    <div style={{ display: 'flex', flexDirection: 'column', border: "1px solid ", width: 700, borderRadius: 5, overflow: 'auto', height: 400 }} >
                                                        <div style={{ display: 'flex', flexDirection: 'row', width: 250 }}>
                                                            <GridItem style={{ height: 50 }}>
                                                                <p style={{ width: 250 }} >{formaCadencia2}</p>
                                                            </GridItem>
                                                            <GridItem style={{ height: 50 }}>
                                                                <p style={{ width: 250 }} >Quantidade</p>
                                                            </GridItem >
                                                        </div>

                                                        {Object.keys(autCad2).map((x, i) =>
                                                            <div key={i} style={{ display: 'flex', flexDirection: 'row', width: 700, backgroundColor: i % 2 != 0 ? 'lightGray' : 'white' }}>
                                                                <GridItem style={{ height: 50 }}>
                                                                    {formaCadencia !== "Diário" ?
                                                                        <p style={{ width: 250 }} >{new Date(autCad2[i].start).toLocaleDateString('pt-br')} -{">"} {new Date(autCad2[i].end).toLocaleDateString('pt-br')}</p>
                                                                        : <p style={{ width: 250 }} >{new Date(autCad2[i].start).toLocaleDateString('pt-br')}</p>}
                                                                </GridItem>
                                                                <GridItem style={{ height: 50 }}>
                                                                    <TextField type="number" style={{ width: 100 }} value={autCad2[i].qtde === undefined ? 0 : autCad2[i].qtde} InputProps={{ inputProps: { min: 0, max: parseFloat(qtde2) } }} onChange={(e) => modCadencia2(e.target.value, i)} />
                                                                </GridItem >
                                                            </div>
                                                        )}</div>
                                                    : <div />
                                                }


                                            </div >

                                        </div >
                                    </GridContainer>
                                    : <GridContainer></GridContainer>

                                }

                                {cont >= 2 ?
                                    <GridContainer>
                                        <div style={{ border: 'solid 1px rgba(0,0,0, .4)', borderRadius: 4, margin: 10, padding: 20, width: '100%' }}>

                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <TextField
                                                        value={qtde3}
                                                        style={{ width: 175, marginTop: 12 }}
                                                        type="number" label='Quantidade'
                                                        onChange={(e) => setQtde3(e.target.value)} />
                                                </GridItem>
                                                {posEmb3 != '' ? <Autocomplete
                                                    id="combo-box-demo"
                                                    options={Object.keys(posEmb3).map((item) => posEmb3[item].name)}
                                                    value={emb3}
                                                    getOptionSelected={(option, value) => option === value || value === ''}
                                                    style={{ width: 175, marginTop: 12 }}
                                                    onChange={(e, v) => setEmb3(v)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            label='Embalagem'
                                                        />
                                                    )}
                                                /> : <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={175}
                                                    timeout={2000000} //3 secs

                                                />}
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={['Milho', 'Pastagem', 'Soja', 'Girassol', 'Cana', 'Capim', 'Cobertura', 'Hortaliça', 'Horta', 'Outros', 'Feijão', 'Plantio', 'N/A']}
                                                        value={cultura3}
                                                        getOptionSelected={(option, value) => option === value || value === ''}
                                                        style={{ width: 175, marginTop: 12 }}
                                                        onChange={(e, v) => setCultura3(v)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                required
                                                                label='Cultura'
                                                            />
                                                        )}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={['Soja', 'Pivo', 'Cana', 'Cobertura', 'Plantio', 'Safrinha', 'Irrigado', 'N/A']}
                                                        value={utilidade3}
                                                        getOptionSelected={(option, value) => option === value || value === ''}
                                                        style={{ width: 175, marginTop: 12 }}
                                                        onChange={(e, v) => setUtilidade3(v)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                required
                                                                label='Utilidade'
                                                            />
                                                        )}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    {itens !== '' ? <Autocomplete
                                                        id="combo-box-demo"
                                                        value={nomeProduto3}
                                                        options={Object.keys(itens).map((item) => itens[item].ItemName)}
                                                        getOptionSelected={(option, value) => option === value || value === ''}
                                                        style={{ width: 333 }}
                                                        onInputChange={(e, v) => setaValoresItem(v, 'nome', 3)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}

                                                                label='Nome Produto'
                                                                multiline
                                                            />
                                                        )}
                                                    /> : <Loader
                                                        type="Circles"
                                                        color="#00BFFF"
                                                        height={20}
                                                        width={20}
                                                        timeout={2000000} //3 secs

                                                    />}
                                                </GridItem>
                                               
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <TextField
                                                        value={uni3}
                                                        style={{ width: 150, marginTop: 12 }}
                                                        type="number"
                                                        label='Valor Unitário'
                                                        onChange={(e) => setUni3(e.target.value)} />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <TextField style={{ width: 150, marginTop: 12 }} value={parseInt(uni3) * parseInt(qtde3)} InputProps={{
                                                        readOnly: true,
                                                    }} label='Valor Total 3' />
                                                </GridItem>
                                            </GridContainer>
                                            {/* Cadencia Produto 3 */}
                                            <div>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={15}>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h4> Total ton restante entregar Produto 3 -{">"}  {parseFloat(qtde3) - (autCad3 != '' ? autCad3.map(amount).reduce(sum) : 0)}</h4>
                                                        </div>
                                                        <div style={{ marginTop: 20 }}>
                                                            <h4> Cadência</h4>
                                                            <TextField style={{ width: 200 }} onChange={(e) => setQtdeEntregaCad3(e.target.value)} value={qtdeEntregaCad3} label='Quantidade por Entrega' />
                                                            <RadioGroup row aria-label="FormaCadencia" name="FormaCadencia" value={formaCadencia3} onChange={handleChangeCad3}>
                                                                <FormControlLabel value="Diário" control={<Radio />} label="Diário" />
                                                                <FormControlLabel value="Semanal" control={<Radio />} label="Semanal" />
                                                                <FormControlLabel value="Mensal" control={<Radio />} label="Mensal" />
                                                            </RadioGroup>
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={15}>
                                                        <Button style={{ backgroundColor: '#012240', marginTop: 30 }} variant="contained" onClick={() => generateCad3()}> Gerar Cadência</Button>


                                                    </GridItem >
                                                </GridContainer >


                                                {autCad3 != '' ?

                                                    <div style={{ display: 'flex', flexDirection: 'column', border: "1px solid ", width: 700, borderRadius: 5, overflow: 'auto', height: 400 }} >
                                                        <div style={{ display: 'flex', flexDirection: 'row', width: 250 }}>
                                                            <GridItem style={{ height: 50 }}>
                                                                <p style={{ width: 250 }} >{formaCadencia3}</p>
                                                            </GridItem>
                                                            <GridItem style={{ height: 50 }}>
                                                                <p style={{ width: 250 }} >Quantidade</p>
                                                            </GridItem >
                                                        </div>

                                                        {Object.keys(autCad3).map((x, i) =>
                                                            <div key={i} style={{ display: 'flex', flexDirection: 'row', width: 700, backgroundColor: i % 2 != 0 ? 'lightGray' : 'white' }}>
                                                                <GridItem style={{ height: 50 }}>
                                                                    {formaCadencia !== "Diário" ?
                                                                        <p style={{ width: 250 }} >{new Date(autCad3[i].start).toLocaleDateString('pt-br')} -{">"} {new Date(autCad3[i].end).toLocaleDateString('pt-br')}</p>
                                                                        : <p style={{ width: 250 }} >{new Date(autCad3[i].start).toLocaleDateString('pt-br')}</p>}
                                                                </GridItem>
                                                                <GridItem style={{ height: 50 }}>
                                                                    <TextField type="number" style={{ width: 100 }} value={autCad3[i].qtde === undefined ? 0 : autCad3[i].qtde} InputProps={{ inputProps: { min: 0, max: parseFloat(qtde3) } }} onChange={(e) => modCadencia3(e.target.value, i)} />
                                                                </GridItem >
                                                            </div>
                                                        )}</div>
                                                    : <div />
                                                }


                                            </div >

                                        </div>
                                    </GridContainer>
                                    : <GridContainer></GridContainer>}



                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                    <div className="d-flex align-items-center">
                                    {cont < 2 && (
                                        <button 
                                            className="btn btn-success m-2"
                                            onClick={handleAddProduct}>
                                            <i className="bi bi-plus-circle"></i> Adicionar Produto
                                        </button>
                                        
                                    )}

                                    {/* Botão para remover o último produto adicionado, somente exibido se há produtos para remover */}
                                    {cont > 0 && (
                                        <button 
                                            className="btn btn-danger m-2"
                                            onClick={handleRemoveProduct}>
                                            <i className="bi bi-dash-circle"></i> Remover Último Produto
                                        </button>
                                    )}
                                </div>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <TextField style={{ width: 200, marginTop: 30 }} value={uni * qtde + uni2 * qtde2 + uni3 * qtde3} InputProps={{
                                            readOnly: true,
                                        }} label='Valor Total Geral' />
                                    </GridItem>
                                </GridContainer>


                            </CardBody >

                        </Card >
                    </GridItem >
                    {/*logistia*/}
                    <div className="container-fluid" style={{ marginTop: "20px" }}>
                        <div className="card w-100">
                            <div className="card-header text-white" style={{ backgroundColor: "#012240" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <LocalShippingIcon style={{ fontSize: "24px", marginRight: "10px" }} />
                                    <div>
                                    <h4 className="fw-bold mb-1">Logística</h4>
                                    <p className="mb-0 fs-6">Campos Logísticos, Cadência + Val Frete</p>
                                    </div>
                                </div>
                            </div>                            
                            <div className="card-body">


                                {/* Origem, Destino e Sub-Região */}
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <label htmlFor="frete" className="form-label">Frete</label>
                                        <select
                                            id="frete"
                                            className="form-select"
                                            value={frete}
                                            onChange={(e) => setFrete(e.target.value)}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="CIF">CIF</option>
                                            <option value="FOB">FOB</option>
                                        </select>
                                    </div>
                                    {/* Origem */}
                                    <div className="col-md-3">
                                        <label htmlFor="selOrigem" className="form-label">Origem</label>
                                        {origemOptions.length > 0 ? (
                                            <Select
                                                id="selOrigem"
                                                options={origemOptions}
                                                value={origemOptions.find((opt) => opt.value === selOrigem)}
                                                onChange={(selectedOption) => setSelOrigem(selectedOption?.value || '')}
                                                placeholder="Selecione a origem"
                                                isClearable
                                                isSearchable
                                            />
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                />
                                                <span className="ms-2">Carregando...</span>
                                            </div>
                                        )}
                                    </div>

                                    {/* Destino */}
                                    <div className="col-md-3">
                                        <label htmlFor="selDestino" className="form-label">Destino</label>
                                        {destinoOptions.length > 0 ? (
                                            <Select
                                                id="selDestino"
                                                options={destinoOptions}
                                                value={destinoOptions.find((opt) => opt.value === selDestino)}
                                                onChange={(selectedOption) => setSelDestino(selectedOption?.value || '')}
                                                placeholder="Selecione o destino"
                                                isClearable
                                                isSearchable
                                            />
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                />
                                                <span className="ms-2">Carregando...</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="selSubReg" className="form-label">
                                            Sub-Região (Raio - KM)
                                        </label>
                                        {subReg !== '' ? (
                                            <select
                                                id="selSubReg"
                                                className="form-select"
                                                value={selSubReg}
                                                onChange={(e) => setSelSubReg(e.target.value)}
                                                disabled={frete === 'FOB'}
                                            >
                                                <option value="">Selecione</option>
                                                {subReg.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                />
                                                <span className="ms-2">Carregando...</span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Roteiro */}
                                <div className="row mb-12">
                                    <div className="col-md-12">
                                        <label htmlFor="roteiro" className="form-label">Roteiro</label>
                                        <textarea
                                            id="roteiro"
                                            className="form-control"
                                            value={roteiro}
                                            onChange={(e) => setRoteiro(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>

                                {/* Botão Pesquisar Frete */}
                                <div className="row mb-3" style={{ marginTop: 10 }}>
                                    <div className="col-md-3">
                                        <button
                                            className="btn btn-primary"
                                            style={{ backgroundColor: '#012240', borderColor: '#012240' }}
                                            onClick={() => calculaFrete()}
                                            disabled={
                                                selSubReg === '' ||
                                                selOrigem === '' ||
                                                selDestino === '' ||
                                                autCad === ''
                                            }
                                        >
                                            Pesquisar Frete
                                        </button>
                                    </div>
                                </div>

                                {/* Valor Frete Tabela */}
                                <div className="row mb-12">
                                    <div className="col-md-6">
                                        <label htmlFor="valFrete" className="form-label">Valor Frete Tabela</label>
                                        <input
                                            type="number"
                                            id="valFrete"
                                            className="form-control"
                                            value={valFrete}
                                            onChange={(e) => setValFrete(e.target.value)}
                                            readOnly={frete === 'FOB'}
                                            disabled={frete === 'FOB'}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="valFreteVendido" className="form-label">
                                            Valor Frete Vendido
                                        </label>
                                        <input
                                            type="number"
                                            id="valFreteVendido"
                                            className="form-control"
                                            value={valFreteVendido}
                                            onChange={(e) => setValFreteVendido(e.target.value)}
                                            disabled={frete === 'FOB'}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>                    
                    </div>
                        {/* Cobrança */}
                    <div className="container-fluid" style={{ marginTop: "20px" }}>
                        <div className="card w-100">
                            <div className="card-header text-white" style={{ backgroundColor: "#012240" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <HomeIcon style={{ fontSize: "24px", marginRight: "10px" }} />
                                    <div>
                                    <h4 className="fw-bold mb-1">Endereço de Cobrança/Correspondência</h4>
                                    <p className="mb-0 fs-6">Cobrança/Correspondência</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {/* Endereço */}
                                <div className="row mb-3">
                                    <div className="col-md-12">
                                        <label htmlFor="rua" className="form-label">Endereço (RUA, AV, SÍTIO, FAZENDA)</label>
                                        <input
                                            type="text"
                                            id="rua"
                                            className="form-control"
                                            value={rua}
                                            onChange={(e) => setRua(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Cidade, Estado e CEP */}
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label htmlFor="cidade" className="form-label">Cidade</label>
                                        <input
                                            type="text"
                                            id="cidade"
                                            className="form-control"
                                            value={cidade}
                                            onChange={(e) => setCidade(e.target.value)}
                                            readOnly
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="estado" className="form-label">Estado</label>
                                        <input
                                            type="text"
                                            id="estado"
                                            className="form-control"
                                            value={estado}
                                            onChange={(e) => setEstado(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="cep" className="form-label">CEP</label>
                                        <input
                                            type="text"
                                            id="cep"
                                            className="form-control"
                                            value={cep}
                                            onChange={(e) => setCep(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>

                                {/* E-mail, Telefone e Caixa Postal */}
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label htmlFor="email" className="form-label">E-Mail de contato do recebedor</label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="form-control"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="phone" className="form-label">Telefone celular de contato do recebedor</label>
                                        <MuiPhoneInput
                                            defaultCountry="br"
                                            id="phone"
                                            style={{ width: '100%' }}
                                            value={phone}
                                            onChange={(e) => setPhoneEntrega(e)}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="caixaPostal" className="form-label">Caixa Postal</label>
                                        <input
                                            type="text"
                                            id="caixaPostal"
                                            className="form-control"
                                            value={caixaPostal}
                                            onChange={(e) => setCaixaPostal(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Condições Contrao*/}
                    <div className="container-fluid" style={{ marginTop: "20px" }}>
                        <div className="card w-100">
                            <div className="card-header text-white" style={{ backgroundColor: "#012240" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <AssignmentIcon style={{ fontSize: "24px", marginRight: "10px" }} />
                                    <div>
                                    <h4 className="fw-bold mb-1">Contrato</h4>
                                    <p className="mb-0 fs-6">Condições Contrato</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {/* Pedido Vinculado */}
                                <div className="row mb-3">
                                    <div className="col-md-12">
                                        <label htmlFor="contrato" className="form-label">
                                            PEDIDO VINCULADO AO CONTRATO DE ABERTURA DE CRÉDITO N º:
                                        </label>
                                        <input
                                            type="text"
                                            id="contrato"
                                            className="form-control"
                                            value={contrato}
                                            onChange={(e) => setContrato(e.target.value)}
                                        />
                                    </div>
                                </div>

                                {/* Vendedor e Vencimento */}
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label htmlFor="vendedor" className="form-label">Vendedor</label>
                                        {Object.keys(colaboradores).length > 0 ? (
                                            <Select
                                                id="vendedor"
                                                options={vendedorOptions} // Lista de opções
                                                value={vendedorOptions.find((opt) => opt.value === vendedor)}
                                                onChange={(selectedOption) => setVendedor(selectedOption?.value || '')}
                                                placeholder="Selecione o vendedor"
                                                isClearable
                                                isSearchable
                                            />
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                />
                                                <span className="ms-2">Carregando...</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="dataCond" className="form-label">Vencimento</label>
                                        <input
                                            type="date"
                                            id="dataCond"
                                            className="form-control"
                                            value={dataCond}
                                            onChange={(e) => setDataCond(e.target.value)}
                                        />
                                    </div>
                                </div>

                                {/* Supervisor */}
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                            <label htmlFor="supervisor" className="form-label">Supervisor</label>
                                            {Object.keys(colaboradores).length > 0 ? (
                                                <Select
                                                    id="supervisor"
                                                    options={supervisorOptions} // Lista de opções
                                                    value={supervisorOptions.find((opt) => opt.value === supervisor)}
                                                    onChange={(selectedOption) => setSupervisor(selectedOption?.value || '')}
                                                    placeholder="Selecione o Supervisor"
                                                    isClearable
                                                    isSearchable
                                                />
                                            ) : (
                                                <div className="d-flex align-items-center">
                                                    <Loader
                                                        type="Circles"
                                                        color="#00BFFF"
                                                        height={20}
                                                        width={20}
                                                    />
                                                    <span className="ms-2">Carregando...</span>
                                                </div>
                                            )}
                                        </div>
                                </div>

                                {/* Gerente */}
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label htmlFor="gerente" className="form-label">Gerente</label>
                                        {Object.keys(colaboradores).length > 0 ? (
                                            <Select
                                                id="gerente"
                                                options={gerenteOptions} // Lista de opções
                                                value={gerenteOptions.find((opt) => opt.value === gerente)}
                                                onChange={(selectedOption) => setGerente(selectedOption?.value || '')}
                                                placeholder="Selecione o Gerente"
                                                isClearable
                                                isSearchable
                                            />
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <Loader
                                                    type="Circles"
                                                    color="#00BFFF"
                                                    height={20}
                                                    width={20}
                                                />
                                                <span className="ms-2">Carregando...</span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Cuidados e Comprador */}
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label htmlFor="care" className="form-label">Aos Cuidados De</label>
                                        <input
                                            type="text"
                                            id="care"
                                            className="form-control"
                                            value={care}
                                            onChange={(e) => setCare(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="comprador" className="form-label">Comprador</label>
                                        <input
                                            type="text"
                                            id="comprador"
                                            className="form-control"
                                            value={comprador}
                                            onChange={(e) => setComprador(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 text-center" style={{ marginTop: 20 }}>
                        <button 
                            className="btn btn-primary"
                            style={{ backgroundColor: '#012240', borderColor: '#012240' }}
                            disabled={wait}
                            onClick={() => geraPlanilha()}
                        >
                            Cadastrar e Enviar Pedido
                        </button>
                    </div>

                </GridContainer >
                {/* <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: 20, overflow: 'scroll', height: 700, borderStyle: 'solid' }} >
                        {files !== '' ? <table >
                            <thead>
                                <tr style={{ width: 700, textAlign: "center", border: "1px solid " }}>
                                    <th style={{ textAlign: "center", border: "1px solid " }}>
                                        Nome Arquivo
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {files !== '' ?
                                    Object.keys(files).map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ width: 700, textAlign: "center", border: "1px solid " }}>
                                                <a href={files[item].link}>{files[item].nomes}</a>
                                            </td>
                                        </tr>
                                    )) : <div></div>}
                            </tbody>
                        </table> : <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            timeout={20000} //3 secs
                        />}
                    </div>
                </GridItem>
            </GridContainer> */}
            </div > : <></>
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendas);
