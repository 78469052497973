import React, { useRef } from 'react';

const SimuladorPrecos = () => {
  // Referência ao iframe
  const cardsRef = useRef(null);

  return (
    <div>
      <iframe
        ref={cardsRef}
        src="https://back.macs.dev.br:5007/simulador"
        width="100%"
        height="800"
        title="Simulador Precos"
        style={{
            border: 'none',
            maxWidth: '100%', // Para manter a responsividade
        }}
        ></iframe>
    </div>
  );
};

export default SimuladorPrecos;