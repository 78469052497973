import React, { useRef } from 'react';

const RelVendedoresSap = () => {
  // Referência ao iframe
  const cardsRef = useRef(null);

  return (
    <div>
      <iframe
        ref={cardsRef}
        src="http://automacao.genfertilizantes.com/relsapxsys"
        width="100%"
        height="800"
        title="Relatorio Vendedores Sap"
        style={{
            border: 'none',
            maxWidth: '100%', // Para manter a responsividade
        }}
        ></iframe>
    </div>
  );
};

export default RelVendedoresSap;