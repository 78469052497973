import React, { useEffect, useState } from "react";
import './Dashboard.css'
import api2 from "../../services/api2";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button.js";
import { TextField } from "@material-ui/core";
import Loader from "react-loader-spinner";
import {
  BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush, LabelList,
  ReferenceLine
} from 'recharts';
import { makeStyles } from "@material-ui/core/styles";
import bgImage from "assets/img/barco.png";
import { getUser, getToken } from '../../utils/utils.js'
import exportFromJSON from "export-from-json";
import api from "services/api";

function GraficosTeste(props) {

  const [token, setToken] = useState('')
  const [permitido, setPermitido] = useState(true)
  const [faturamento, setFaturamento] = useState('')
  const [tipoFaturamento, setTipoFaturamento] = useState('')
  const [carteira, setCarteira] = useState('')
  const [tipoCarteira, setTipoCarteira] = useState('')
  const [gerentes, setGerentes] = useState('')
  const [gerente, setGerente] = useState('')
  const [produtos, setProdutos] = useState('')
  const [produto, setProduto] = useState('')
  const [clientes, setClientes] = useState('')
  const [cliente, setCliente] = useState('')
  const [carteiraVendedores, setCarteiraVendedores] = useState('')
  const [carteiraFiltradaPorVendedor, setCarteiraFiltradaPorVendedor] = useState('')
  const [drillDownCarteira, setDrillDownCarteira] = useState(false)
  const [anoFiltro, setAnoFiltro] = useState('')
  const [mesFiltro, setMesFiltro] = useState('')

  const [aba, setAba] = useState(0)

  const classes = useStyles()

  useEffect(() => {
    async function fetchMyAPI() {
      const response_gerentes = await api.get('return_gerentes', { params: { userId: getUser(), token: getToken() } });
      setGerentes(response_gerentes.data)

      const response_produtos_saldos = await api.get('return_produtos_saldos', { params: { userId: getUser(), token: getToken() } });
      setProdutos(response_produtos_saldos.data)

      const response_carteira_vendedores = await api.get('return_carteira_vendedores', { params: { userId: getUser(), token: getToken() } });
      setCarteiraVendedores(response_carteira_vendedores.data)
    }
    fetchMyAPI();

  }, [])

  async function consultarInfos(name) {
    let gerente_aux = gerente !== null ? gerente : ''
    let produto_aux = produto !== null ? produto : ''
    if (name === 'faturamento') {
      setFaturamento('')
      const return_infos_faturamento_top = await api2.get("/return_infos_faturamento_top", { params: { userId: getUser(), token: getToken(), 'vendedor': gerente_aux, 'agrupamento': tipoFaturamento, 'item': produto_aux } });
      setFaturamento(return_infos_faturamento_top.data)
    }
    else if (name === 'carteira') {
      setCarteira('')
      const return_infos_carteira_agrupamento = await api2.get("/return_infos_carteira_agrupamento", { params: { userId: getUser(), token: getToken(), 'vendedor': gerente_aux, 'agrupamento': tipoCarteira, 'item': produto_aux } });
      setCarteira(return_infos_carteira_agrupamento.data)
    }
  }

  function baixarInfo(tipoDownload) {
    let gerente_aux = gerente !== null ? gerente : ''
    let produto_aux = produto !== null ? produto : ''
    if (tipoDownload === 'faturamento') {
      const data = faturamento

      const fileName = 'Faturamentos_' + tipoFaturamento + '_' + produto_aux + '_' + gerente_aux
      const exportType = exportFromJSON.types.xls

      exportFromJSON({ data, fileName, exportType })
    }
    else if (tipoDownload === 'carteira') {
      const data = carteira

      const fileName = 'Carteira_' + tipoCarteira + '_' + produto_aux + '_' + gerente_aux
      const exportType = exportFromJSON.types.xls

      exportFromJSON({ data, fileName, exportType })
    }

  }

  async function drillDownCarteiraGerentes(nomeVendedor) {
    setDrillDownCarteira(true)
    console.log(nomeVendedor)
    const response_carteira_filtrada_vendedor = await api.get('return_carteira_filtrada_vendedor', { params: { userId: getUser(), token: getToken(), 'vendedor': nomeVendedor } });
    console.log(response_carteira_filtrada_vendedor.data)
    setCarteiraFiltradaPorVendedor(response_carteira_filtrada_vendedor.data)


  }



  return (
    permitido === true ?
      <div >
        <div style={{ background: 'black', color: 'white', display: 'flex', flexDirection: 'row' }}>
          <Button style={{ background: 'black', color: aba === 0 ? 'lightblue' : 'white' }} onClick={() => setAba(0)}>Faturamentos</Button>

          <Button style={{ background: 'black', color: aba === 1 ? 'lightblue' : 'white' }} onClick={() => setAba(1)}>Início Entrega Carteira</Button>
          <Button style={{ background: 'black', color: aba === 1 ? 'lightblue' : 'white' }} onClick={() => setAba(2)}>Carteira Gerentes</Button>
          {/* <Button style={{ background: 'black', color: aba === 2 ? 'lightblue' : 'white' }} onClick={() => setAba(2)}>Comparação Faturamentos Vendedores</Button> */}


        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Autocomplete
            id="combo-box-demo"
            options={Object.keys(gerentes).map((item) => gerentes[item].GerentePedido)}

            getOptionSelected={(option, value) => option === value || value === ''}
            style={{ width: 250, marginTop: 36, marginRight: 15 }}
            onChange={(e, v) => (setGerente(v), console.log(gerente))}
            value={gerente}

            renderInput={(params) => (
              <TextField
                {...params}
                label="Filtro Gerente"

              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            options={['2019', '2020', '2021', '2022', '2023', '2024']}

            getOptionSelected={(option, value) => option === value || value === ''}
            style={{ width: 250, marginTop: 36, marginRight: 15 }}
            onChange={(e, v) => (setAnoFiltro(v))}
            value={anoFiltro}

            renderInput={(params) => (
              <TextField
                {...params}
                label="Filtro Ano"

              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            options={['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']}

            getOptionSelected={(option, value) => option === value || value === ''}
            style={{ width: 250, marginTop: 36, marginRight: 15 }}
            onChange={(e, v) => (setMesFiltro(v))}
            value={mesFiltro}

            renderInput={(params) => (
              <TextField
                {...params}
                label="Filtro Mês"

              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            options={Object.keys(produtos).map((item) => produtos[item].produto)}

            getOptionSelected={(option, value) => option === value || value === ''}
            style={{ width: 250, marginTop: 36 }}
            onChange={(e, v) => (setProduto(v))}
            value={produto}

            renderInput={(params) => (
              <TextField
                {...params}
                label="Filtro Produto"

              />
            )}
          />

        </div>
        <div style={{ height: 1550, overflow: 'hidden', backgroundColor: 'white' }}>
          {aba === 0 ?
            <div style={{ margin: 15 }}>
              <div>
                <Autocomplete
                  id="combo-box-demo"
                  options={['ano', 'mes', 'semana', 'dia']}
                  getOptionSelected={(option, value) => option === value || value === ''}
                  style={{ width: 250, marginTop: 36 }}
                  onChange={(e, v) => setTipoFaturamento(v)}
                  value={tipoFaturamento}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Agrupamento"

                    />
                  )}
                />

                <Button style={{ backgroundColor: '#020b24' }} onClick={() => consultarInfos('faturamento')} > Consultar informações</Button>
              </div>
              {faturamento != '' ?
                <div >
                  <div style={{ display: 'flex', flexDirection: 'row', width: 1000, height: 400, backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                    <BarChart
                      width={1000}
                      height={400}
                      data={faturamento}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      onClick={(e) => console.log(e)}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="data" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Faturado" fill="#8884d8" />
                      <Brush dataKey="filtro" height={30} stroke="#8884d8" />
                    </BarChart>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', width: 1000, height: 400, backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>

                    <LineChart
                      width={1000}
                      height={400}
                      data={faturamento}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="data" />
                      <YAxis />
                      <Tooltip />
                      <Legend />

                      <Line type="monotone" dataKey="Faturado" stroke="#82ca9d" activeDot={{ r: 8 }} />
                      <Brush dataKey="filtro" height={30} stroke="#8884d8" />

                    </LineChart>
                  </div>
                  <div key={0} style={{ height: 500, overflow: 'auto', border: "1px solid " }}>
                    <Button style={{ backgroundColor: '#020b24' }} variant="contained" onClick={() => baixarInfo('faturamento')}> Baixar dados</Button>

                    <table>
                      <thead className={classes.header}>
                        <tr>
                          <th style={{ color: 'white' }}>Data</th>
                          <th style={{ color: 'white' }}>Quantidade Faturada</th>

                        </tr>
                      </thead>

                      <tbody style={{ background: '#ededed' }}>
                        {Object.keys(faturamento).map((index, item) =>

                          <tr>
                            <td className={classes.tdBorder}> {faturamento[index].data}</td>
                            <td className={classes.tdBorder}>{faturamento[index].Faturado}</td>
                          </tr>
                        )}
                      </tbody >
                    </table>
                  </div >
                </div> : tipoFaturamento !== '' ? <Loader
                  type="Circles"
                  color="#00BFFF"
                  height={20}
                  width={20}
                  timeout={2000000} //3 secs
                /> : 'Selecione um agrupamento'}
            </div> : aba === 1 ?
              <div style={{ margin: 15 }}>
                <div>
                  <Autocomplete
                    id="combo-box-demo"
                    options={['ano', 'mes', 'semana', 'dia']}
                    getOptionSelected={(option, value) => option === value || value === ''}
                    style={{ width: 250, marginTop: 36 }}
                    onChange={(e, v) => setTipoCarteira(v)}
                    value={tipoCarteira}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Agrupamento"

                      />
                    )}
                  />

                  <Button style={{ backgroundColor: '#020b24' }} onClick={() => consultarInfos('carteira')} > Consultar informações</Button>
                </div>
                {carteira != '' ?
                  <div >
                    <div style={{ display: 'flex', flexDirection: 'row', width: 1000, height: 400, backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                      <BarChart
                        width={1000}
                        height={400}
                        data={carteira}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="data" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Carteira" fill="#8884d8" />
                        <Brush dataKey="filtro" height={30} stroke="#8884d8" />
                      </BarChart>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 1000, height: 400, backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>

                      <LineChart
                        width={1000}
                        height={400}
                        data={carteira}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="data" />
                        <YAxis />
                        <Tooltip />
                        <Legend />

                        <Line type="monotone" dataKey="Carteira" stroke="#82ca9d" activeDot={{ r: 8 }} />
                      </LineChart>
                    </div>
                    <div key={0} style={{ height: 500, overflow: 'auto', border: "1px solid " }}>
                      <Button style={{ backgroundColor: '#020b24' }} variant="contained" onClick={() => baixarInfo('carteira')}> Baixar Dados</Button>

                      <table>
                        <thead className={classes.header}>
                          <tr>
                            <th style={{ color: 'white' }}>Data</th>
                            <th style={{ color: 'white' }}>Quantidade Faturada</th>

                          </tr>
                        </thead>

                        <tbody style={{ background: '#ededed' }}>
                          {Object.keys(carteira).map((index, item) =>

                            <tr>
                              <td className={classes.tdBorder}> {carteira[index].data}</td>
                              <td className={classes.tdBorder}>{carteira[index].Carteira}</td>
                            </tr>
                          )}
                        </tbody >
                      </table>
                    </div >
                  </div> : tipoCarteira !== '' ? <Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={2000000} //3 secs
                  /> : 'Selecione um agrupamento'}
              </div> : aba === 2 ?
                <div style={{ margin: 15 }}>

                  {carteiraVendedores != '' ?
                    drillDownCarteira === false ?
                      <div >
                        <div style={{ display: 'flex', flexDirection: 'row', width: 1000, height: 400, backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                          <BarChart
                            width={1000}
                            height={400}
                            data={carteiraVendedores}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                            onClick={(e) => drillDownCarteiraGerentes(e.activeLabel)}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="GerentePedido"  interval={0}  fontSize={14} />
                            <YAxis label={{ value: 'Carteira', angle: -90, position: 'insideLeft' }} domain={[0, 35000]}/>
                            <Tooltip  formatter={(value) => new Intl.NumberFormat('br').format(value)} />
                            <Legend />
                            <Bar dataKey="Carteira" fill="#8884d8" >
                                <LabelList dataKey="Carteira" position="top"  fontSize={15}  formatter={(value) => new Intl.NumberFormat('br').format(value)}/>
                              </Bar>

                          </BarChart>
                        </div>


                      </div> :
                      carteiraFiltradaPorVendedor !== '' ?
                        < div >
                          <div style={{ display: 'flex', flexDirection: 'row', width: 1000, height: 400, backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                            <BarChart
                              width={1000}
                              height={400}
                              data={JSON.parse(carteiraFiltradaPorVendedor['agCliente'])}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}

                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="Cliente"  label={{ value: 'Clientes', position: 'insideBottom' }} tick={{fontSize: 0}} />
                              <YAxis label={{ value: 'Carteira', angle: -90, position: 'insideLeft' }} />
                              <Tooltip  formatter={(value) => new Intl.NumberFormat('br').format(value)} />
                              <Legend />
                              <Bar dataKey="Carteira" fill="#8884d8" >
                                <LabelList dataKey="Carteira" position="top"  fontSize={12}  formatter={(value) => new Intl.NumberFormat('br').format(value)} angle={-15}/>
                              </Bar>
                            </BarChart>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', width: 1000, height: 400, backgroundImage: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                            <BarChart
                              width={1000}
                              height={400}
                              data={JSON.parse(carteiraFiltradaPorVendedor['agProdutos'])}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}

                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="Item" label={{ value: 'Itens', position: 'insideBottom' }} tick={{fontSize: 0}} />
                              <YAxis  label={{ value: 'Carteira', angle: -90, position: 'insideLeft' }} />
                              <Tooltip  formatter={(value) => new Intl.NumberFormat('br').format(value)} />
                              <Legend />
                              <Bar dataKey="Carteira" fill="#8884d8" >
                                <LabelList dataKey="Carteira" position="top" fontSize={12}  formatter={(value) => new Intl.NumberFormat('br').format(value)}  angle={-15} />
                              </Bar>

                            </BarChart>
                          </div>
                          <Button style={{ backgroundColor: '#020b24' }} onClick={() => (setDrillDownCarteira(false),setCarteiraFiltradaPorVendedor(''))} > Voltar</Button>
                        </div> : <Loader
                          type="Circles"
                          color="#00BFFF"
                          height={20}
                          width={20}
                          timeout={2000000} //3 secs
                        /> : 'Selecione um agrupamento'}
                </div> : null}
        </div>

      </div > : <></>
  );
}
const useStyles = makeStyles({
  inputGroup: {
    display: 'flex',
    flexDirection: 'row',
    margin: 10
  },

  tdBorder: {
    border: '1px solid rgba(0,0,0, .4)'
  },

  paper: {
    width: 300
  },

  divRow: {
    display: 'flex',
    flexDirection: 'row'
  },

  inputDiv: {

    padding: 20,
  },

  textField: {
    width: 300,
    margin: 10
  },

  divTabela: {
    height: 600,
  },

  header: {
    backgroundColor: 'black',
    position: 'sticky',
    zIndex: 2,
    top: 0
  },

  thStyle: {
    padding: 5,
    fontSize: 14,
    background: '#ED3237',
    whiteSpace: 'nowrap',
    fontWeight: 'bold'
  },

  thArquivados: {
    background: '#666666',
    fontSize: 12,
    padding: 5,
    whiteSpace: 'nowrap'
  },

  thFinanceiro: {
    padding: 5,
    fontSize: 14,
    background: '#3d42e0',
    whiteSpace: 'nowrap',
    fontWeight: 'bold'

  },

  thLineup: {
    padding: 5,
    fontSize: 14,
    background: '#36c416',
    whiteSpace: 'nowrap',
    fontWeight: 'bold'

  },

  thDesembaraço: {
    padding: 5,
    fontSize: 14,
    background: '#383838',
    whiteSpace: 'nowrap',
    fontWeight: 'bold'

  },

  thDespesa: {
    padding: 5,
    fontSize: 14,
    background: '#3c1854',
    whiteSpace: 'nowrap',
    fontWeight: 'bold'

  },

  thHistorico: {
    padding: 5,
    fontSize: 14,
    background: '#cccbca'
  },

  cellContent: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 14,
    padding: 5
  },

  cellContentBig: {
    minWidth: 100,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 14,
    padding: 5
  },

  cellContentLeft: {
    fontWeight: 'bold',
    fontSize: 14,
    padding: 5,
    display: 'flex',
    flexDirection: 'row'
  },

  cellContentArquivado: {
    background: 'lightgray',
    fontSize: 12
  },

  cellContentFixed: {
    width: 90,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 14
  },

  autoComplete: {
    width: 80,
  },

  red: { background: 'rgba(255, 77, 77, .8)' },

  green: { background: 'rgba(255, 214, 99, .8)' },

  dataWidth: {
    width: 113
  },

  divRelatorio: {
    border: '1px solid rgba(0, 0, 0, .4)',
    borderRadius: 4,
    width: 300,
    padding: 10,
    margin: 5,
    maxHeight: 48
  }
})

export default GraficosTeste;
