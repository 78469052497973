import React, { useState } from 'react';
import { Button, Menu, MenuItem, Typography, Divider } from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';

export default function AdminNavbarLinks(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const fullName = localStorage.getItem('user') || 'Visitante';
    const username = fullName.split(' ')[0]; // Extrai apenas o primeiro nome

    const handleProfileMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };

    const pushConfigurarConta = () => {
        window.location.replace('https://sysgen.genfertilizantes.com/admin/ConfigurarConta');
    };

    return (
        <div style={{ backgroundColor: 'transparent', padding: '10px 20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <Button
                        aria-owns={open ? 'menu-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={handleProfileMenu}
                        color="inherit"
                        startIcon={<PersonIcon />}
                    >
                        Olá, {username}
                    </Button>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>Perfil</MenuItem>
                        <MenuItem onClick={pushConfigurarConta}>Configuração</MenuItem>
                        <Divider />
                        <MenuItem onClick={handleLogout}>Sair</MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    );
}